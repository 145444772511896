import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {IntlTelInputComponent} from "intl-tel-input-ng";
import {Regex} from "../../../core/regex/regex";
import {AppTranslateService} from "../../../core/services/app-translate/app-translate.service";

@Component({
    selector: 'app-tel-input',
    templateUrl: './tel-input.component.html',
    styleUrls: ['./tel-input.component.scss']
})
export class TelInputComponent implements OnInit,  AfterViewInit {

    @ViewChild('telInput') telInput: IntlTelInputComponent;
    @Input() public labelText: string;
    @Output() validatedPhone = new EventEmitter<string>();
    @Output() dirty = new EventEmitter<void>();
    E164PhoneNumber: string | null;

    constructor(
        private translateService: AppTranslateService
    ) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        document.getElementById('intl-tel-input-name').inputMode = 'numeric';
        document.getElementById('intl-tel-input-name').onkeypress = (event) => {
            return (event.charCode >= 48 && event.charCode <= 57);
        };
        const dirtyEvent = this.dirty;
        document.getElementById('intl-tel-input-name').addEventListener('focusout', function (event) {
            dirtyEvent.emit();
        });
    }


    numberChanged(event): void {
        const dialCode = this.telInput.intlTelInput.selectedCountryData.dialCode;
        const phoneNumber = this.telInput.phoneNumber.replace(Regex.NON_NUMERIC_CHARS, '');
        const tel = `${dialCode}${phoneNumber}`;

        if (tel.length >= 10) {
            this.validatedPhone.emit(tel);
        }
    }

    initialCountry(): string {
        const lang = this.translateService.getSelectedLanguage();
        let country = 'tr';
        if (lang.code === 'en') {
            country = 'us';
        }
        return country;
    }
}
