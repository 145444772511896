import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

    @Input() showDismiss = false;
    @Input() type = 'success';
    @ViewChild('alert', { static: true }) alert: ElementRef;

    constructor() {
    }

    ngOnInit(): void {
    }

    closeAlert(): void{
        this.alert.nativeElement.remove();
    }
}
