import {Injectable} from '@angular/core';
import {LocalStorageService} from "../local-storage/local-storage.service";
import {TranslateService} from "@ngx-translate/core";
import {Language} from "../../../models/language/language.model";

@Injectable({
    providedIn: 'root'
})
export class AppTranslateService {

    constructor(
        private localStorageService: LocalStorageService,
        private translateService: TranslateService
    ) {
    }

    init(): void {
        const languages = this.langCodeList();

        this.translateService.addLangs(languages);
        this.setLang(this.getSelectedLanguage());
    }

    setLang(lang: Language): void {
        this.translateService.setDefaultLang(lang.code);
        this.translateService.use(lang.code);
        this.localStorageService.setItem('language', lang);
    }

    getSelectedLanguage(): Language {
        if (this.localStorageService.has('language')) {
            return this.localStorageService.getItem('language');
        } else {
            return this.getDefaultLang();
        }
    }

    getDefaultLang(): Language {
        return this.getLanguageList().find(l => {
            if (l.isDefault) {
                return l;
            }
        });
    }

    getLanguageList(): Language[] {
        return [
            new Language(1, 'English', 'en', true, 'united states'),
            new Language(2, 'Türkçe', 'tr', false, 'turkey')
        ];
    }

    private langCodeList(): string[] {
        const langList = this.getLanguageList();
        return langList.map(l => {
            return l.code
        });
    }

    validateLangCode(lang: string): boolean {
        return (this.langCodeList().findIndex(z => z === lang) >= 0);
    }

    getLang(code: string): Language {
        return this.getLanguageList().find(l => l.code === code);
    }
}
