import {Injectable} from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable({
    providedIn: 'root'
})
export class DeviceInfoService {

    constructor(
        private deviceDetectorService: DeviceDetectorService
    ) {
    }

    mobileBasedPlatformName(): string {
        const osName = this.deviceDetectorService.os.toLowerCase();
        let platformName = 'android';
        if ((osName === 'ios') || (osName === 'macos')) {
            platformName = 'ios';
        }

        return platformName;
    }
}
