import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AppTranslateService} from "./core/services/app-translate/app-translate.service";
import {LocalStorageService} from "./core/services/local-storage/local-storage.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'sportist-web';

    constructor(
        private route: ActivatedRoute,
        private translateService: AppTranslateService,
        private localStorage: LocalStorageService
    ) {
        this.route.queryParams.subscribe(params => {
            // optional language query param
            if (params.hasOwnProperty('l')) {
                const selectedLang = params.l;
                if (this.translateService.validateLangCode(selectedLang)) {
                    this.translateService.setLang(this.translateService.getLang(selectedLang));
                }
            }

            const utmParams = Object.keys(params)
                .filter(key => key.includes('utm_'))
                .reduce((p, k) => ({...p, [k]: params[k]}), {});

            if (Object.keys(utmParams).length !== 0) {
                this.localStorage.setItem('utm', utmParams);
            }
        });
    }
}
