<nav class="navbar navbar-expand-lg navbar-light bg-dark-200">
    <div class="container-fluid">
        <div class="justify-content-center">
            <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item p-2">
                    <img src="assets/sportist/sportist-min-logo.png" width="100"/>
                </li>
            </ul>
        </div>

        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 lang-select-nav">
            <li class="nav-item" style="margin-right: 15px;" >
                <a class="nav-link btn btn-primary p-1 btn-sm text-white" [routerLink]="['/register']" >
                    {{ 'register' | translate }}
                </a>
            </li>
            <ng-container *ngFor="let l of languageList;">
                <li class="nav-item">
                    <button [ngClass]="{'btn ': true, 'btn-light': (l.code == getSelectedLang().code)}" type="button" (click)="setLang(l)">
                        <i [class]="l.iconCode + ' flag'"></i>
                    </button>
                </li>
            </ng-container>
        </ul>
    </div>
</nav>
