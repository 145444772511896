export class Language {
    constructor(
        public id: number,
        public name: string,
        public code: string,
        public isDefault: boolean,
        public iconCode?: string
    ) {
    }
}
