import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {ApiResponse} from "../../../models/api-response/api-response.model";
import {PaymentPlan} from "../../../models/payment/payment-plan/payment-plan.model";

@Injectable({
    providedIn: 'root'
})
export class PaymentService extends ApiService {

    plans(os: string): Observable<ApiResponse<{ list: PaymentPlan[] }>> {
        return this.get(`Payment/plans`, {
            os
        });
    }
}
