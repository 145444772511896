import {Injectable} from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    private name = 'default';

    constructor(
        private spinnerService: NgxSpinnerService
    ) {
    }

    setLoaderName(name: string) {
        this.name = name;
        return this;
    }

    show(): void {
        this.spinnerService.show(this.name);
    }

    hide(): void {
        this.spinnerService.hide(this.name);
    }
}
