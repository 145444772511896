import {Component, OnInit} from '@angular/core';
import {ReCaptchaV3Service} from "ng-recaptcha";
import {Observable} from "rxjs";

@Component({
    selector: 'app-recaptcha',
    templateUrl: './recaptcha.component.html',
    styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {

    constructor(
        private reCaptchaV3Service: ReCaptchaV3Service
    ) {
    }

    ngOnInit(): void {
    }

    executeToken(action: string): Observable<string> {
        return this.reCaptchaV3Service.execute(action);
    }
}
