import {Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie-service";

const APP_PREFIX = `SP-`;

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor(
        private cookieService: CookieService
    ) {
    }

    setItem(key: string, value: any): void {
        this.cookieService.set(`${APP_PREFIX}${key}`, JSON.stringify(value));
    }

    getItem(key: string): any {
        return JSON.parse(this.cookieService.get(`${APP_PREFIX}${key}`));
    }

    removeItem(key: string): void {
        this.cookieService.delete(`${APP_PREFIX}${key}`);
    }

    has(key: string): boolean {
        return this.cookieService.check(`${APP_PREFIX}${key}`);
    }
}
