import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MdbAccordionModule} from "mdb-angular-ui-kit/accordion";
import {MdbCarouselModule} from "mdb-angular-ui-kit/carousel";
import {MdbCheckboxModule} from "mdb-angular-ui-kit/checkbox";
import {MdbCollapseModule} from "mdb-angular-ui-kit/collapse";
import {MdbDropdownModule} from "mdb-angular-ui-kit/dropdown";
import {MdbFormsModule} from "mdb-angular-ui-kit/forms";
import {MdbModalModule} from "mdb-angular-ui-kit/modal";
import {MdbPopoverModule} from "mdb-angular-ui-kit/popover";
import {MdbRadioModule} from "mdb-angular-ui-kit/radio";
import {MdbRangeModule} from "mdb-angular-ui-kit/range";
import {MdbRippleModule} from "mdb-angular-ui-kit/ripple";
import {MdbScrollspyModule} from "mdb-angular-ui-kit/scrollspy";
import {MdbTabsModule} from "mdb-angular-ui-kit/tabs";
import {MdbTooltipModule} from "mdb-angular-ui-kit/tooltip";
import {MdbValidationModule} from "mdb-angular-ui-kit/validation";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {environment} from "../../environments/environment";
import {AppTranslateService} from "../core/services/app-translate/app-translate.service";
import {TimePipe} from "../core/pipes/time.pipe";
import {SecondsToTimeTextPipe} from "../core/pipes/seconds-to-time-text.pipe";
import { NavbarComponent } from './components/theme/navbar/navbar.component';
import {NgxSpinnerModule} from "ngx-spinner";
import { LoaderComponent } from './components/theme/loader/loader.component';
import { DownloadBtnComponent } from './components/theme/download-btn/download-btn.component';
import { TelInputComponent } from './components/tel-input/tel-input.component';
import {IntlTelInputNgModule} from "intl-tel-input-ng";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {RouterModule} from "@angular/router";
import { PremiumPlansComponent } from './components/payment/premium-plans/premium-plans.component';
import { AlertComponent } from './components/theme/alert/alert.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, `${environment.i18nPrefix}/assets/i18n/`, '.json');
}

@NgModule({
    declarations: [
        TimePipe,
        SecondsToTimeTextPipe,
        NavbarComponent,
        LoaderComponent,
        DownloadBtnComponent,
        TelInputComponent,
        RecaptchaComponent,
        PremiumPlansComponent,
        AlertComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        MdbAccordionModule,
        MdbCarouselModule,
        MdbCheckboxModule,
        MdbCollapseModule,
        MdbDropdownModule,
        MdbFormsModule,
        MdbModalModule,
        MdbPopoverModule,
        MdbRadioModule,
        MdbRangeModule,
        MdbRippleModule,
        MdbScrollspyModule,
        MdbTabsModule,
        MdbTooltipModule,
        MdbValidationModule,
        NgxSpinnerModule,
        RecaptchaV3Module,
        IntlTelInputNgModule.forRoot(),
        FormsModule,
        RouterModule
    ],
    exports: [
        TranslateModule,
        ReactiveFormsModule,
        MdbAccordionModule,
        MdbCarouselModule,
        MdbCheckboxModule,
        MdbCollapseModule,
        MdbDropdownModule,
        MdbFormsModule,
        MdbModalModule,
        MdbPopoverModule,
        MdbRadioModule,
        MdbRangeModule,
        MdbRippleModule,
        MdbScrollspyModule,
        MdbTabsModule,
        MdbTooltipModule,
        MdbValidationModule,
        TimePipe,
        SecondsToTimeTextPipe,
        NavbarComponent,
        LoaderComponent,
        DownloadBtnComponent,
        TelInputComponent,
        RecaptchaComponent,
        PremiumPlansComponent,
        AlertComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        {
            provide:  RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptchaKey
        }
    ]
})
export class SharedModule {

    constructor(
        private appTranslateService: AppTranslateService,
        private translateService: TranslateService
    ) {
        this.appTranslateService.init();
    }

    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: SharedModule
        }
    }

}
