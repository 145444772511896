import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }
    async transform(value: string): Promise<string> {
        const timeParts = value.split(':');
        const hours = this.timePartToNumber(timeParts[0]);
        const minutes = this.timePartToNumber(timeParts[1]);
        const seconds = this.timePartToNumber(timeParts[2]);

        let result = '';

        if (hours > 0){
            const hourText = await this.translateService.get('hour').toPromise();
            result += `${hours} ${hourText} `;
        }

        if (minutes > 0){
            const minuteText = await this.translateService.get('minute').toPromise();
            result += `${minutes} ${minuteText} `;
        }

        if (seconds > 0){
            const secondText = await this.translateService.get('second').toPromise();
            result += `${seconds} ${secondText}`;
        }

        return result;
    }

    private timePartToNumber(text: string): number{
        if (text[0] == '0') text = text[1];
        return Number(text);
    }

}
