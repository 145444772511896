import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

    @Input() public loaderName = 'default';
    @Input() public fullScreen = true;
    @Input() public backgroundOpacity: number = 1;
    constructor() {
    }

    ngOnInit(): void {
    }

}
