<form #form="ngForm">
    <intl-tel-input
        #telInput
        [label]="labelText"
        [cssClass]="'form-control'"
        [labelCssClass]="'form-label'"
        [required]="true"
        [options]="{preferredCountries: ['tr', 'us'], initialCountry: initialCountry()}"
        [onlyLocalized]="false"
        inputmode="numeric"
        (E164PhoneNumberChange)="numberChanged($event)"
        [(E164PhoneNumber)]="E164PhoneNumber"></intl-tel-input>
</form>

