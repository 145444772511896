<div class="row plan-list">
    <app-loader [backgroundOpacity]="0.7" [fullScreen]="false" [loaderName]="loaderName"></app-loader>

    <ng-container *ngFor="let plan of plans;">
        <div class="col-6 plan-box">
            <div class="plan-container shadow-5-strong">
                <h5 class="title">
                    {{ plan.title }}
                </h5>
                <ng-template [ngIf]="(plan.tagTitle) && (plan.tagTitle.length > 0)">
                <span class="plan-tag badge badge-primary">
                    {{ plan.tagTitle }}
                </span>
                </ng-template>
                <div class="prices-content mt-3">
                    <ng-template [ngIfElse]="noDiscount" [ngIf]="(plan.discountPrice) && (plan.discountPrice > 0)">
                        <span class="old-price">
                            {{ plan.price }} {{ plan.currency.symbol }}
                        </span>
                        <span class="price">
                            {{ plan.discountPrice }} {{ plan.currency.symbol }}
                        </span>
                    </ng-template>
                    <ng-template #noDiscount>
                        <span class="price">
                            {{ plan.price }} {{ plan.currency.symbol }}
                        </span>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-container>
</div>
