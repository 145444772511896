import {Component, OnInit} from '@angular/core';
import {DeviceInfoService} from "../../../../core/services/device-info/device-info.service";
import {PaymentPlan} from "../../../../models/payment/payment-plan/payment-plan.model";
import {LoaderService} from "../../../services/loader.service";
import {PaymentService} from "../../../../core/services/api/payment.service";

@Component({
    selector: 'app-premium-plans',
    templateUrl: './premium-plans.component.html',
    styleUrls: ['./premium-plans.component.scss']
})
export class PremiumPlansComponent implements OnInit {

    private platform = '';
    plans: PaymentPlan[] = [];
    loaderName = 'paymentPlanLoader';

    constructor(
        private deviceInfo: DeviceInfoService,
        private loaderService: LoaderService,
        private paymentService: PaymentService
    ) {
        this.loaderService.setLoaderName(this.loaderName);
    }

    ngOnInit(): void {
        this.platform = this.deviceInfo.mobileBasedPlatformName();
        this.setPlans();
    }

    private setPlans(): void {
        this.loaderService.show();
        this.paymentService.plans(this.platform)
            .subscribe(result => {
                this.loaderService.hide();
                if (this.paymentService.isSuccess(result)) {
                    this.plans = result.data.list;
                }
            });
    }
}
