import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppTranslateService} from "../services/app-translate/app-translate.service";

@Injectable()
export class HttpLanguageInterceptor implements HttpInterceptor {

    constructor(
        private translateService: AppTranslateService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const selectedLang = this.translateService.getSelectedLanguage();
        request = request.clone({
            setHeaders: {
                LANG: selectedLang.code
            }
        });
        return next.handle(request);
    }
}
