import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: 'secondsToTimeText'
})
export class SecondsToTimeTextPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    async transform(totalSeconds: number): Promise<string> {
        const hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        let result = ``;

        if (hours > 0) {
            const hourText = await this.translateService.get('hour').toPromise();
            result += `${hours} ${hourText} `;
        }

        if (minutes > 0){
            const minuteText = await this.translateService.get('minute').toPromise();
            result += `${minutes} ${minuteText} `;
        }

        if (seconds > 0){
            const secondText = await this.translateService.get('second').toPromise();
            result += `${seconds} ${secondText}`;
        }
        return result;
    }

}
