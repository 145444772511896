import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse} from "../../../models/api-response/api-response.model";
import {environment} from "../../../../environments/environment";
import {LocalStorageService} from "../local-storage/local-storage.service";

@Injectable({
    providedIn: 'root'
})
export abstract class ApiService {

    protected constructor(
        private httpClient: HttpClient,
        protected localStorageService: LocalStorageService
    ) {
    }

    protected get(url: string, data: object = {}): Observable<ApiResponse<any>> {
        let params = Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
        if(params.length > 0) params = `?${params}`;
        return this.httpClient.get<ApiResponse<any>>(`${environment.apiUrl}${url}${params}`);
    }

    protected post(url: string, data: object = {}): Observable<ApiResponse<any>> {
        return this.httpClient.post<ApiResponse<any>>(`${environment.apiUrl}${url}`, data);
    }

    public isSuccess(response: ApiResponse<any>): boolean{
        return response.result.isSuccess;
    }
}
