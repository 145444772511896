import {Component, OnInit} from '@angular/core';
import {Language} from "../../../../models/language/language.model";
import {AppTranslateService} from "../../../../core/services/app-translate/app-translate.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    languageList: Language[] = [];

    constructor(
        private translateService: AppTranslateService
    ) {
        this.languageList = this.translateService.getLanguageList();
    }

    ngOnInit(): void {
    }

    setLang(l: Language): void{
        if (l.code != this.getSelectedLang().code){
            this.translateService.setLang(l);
        }
    }

    getSelectedLang(): Language{
        return this.translateService.getSelectedLanguage();
    }
}
